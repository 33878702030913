import SmoothParalax from "../utils/paralax";

const images = [
  "https://ucarecdn.com/55a42334-c1c9-4ba2-8fe5-29e1699c0dbc/-/preview/764x1368/-/quality/smart/",
  "https://ucarecdn.com/89779cf5-56fd-44cc-aa0b-c1af31c72660/-/preview/764x1368/-/quality/smart/",
  "https://ucarecdn.com/0cfbd1f8-2229-4fbe-a410-95085778ce61/-/preview/764x1368/-/quality/smart/",
  "https://ucarecdn.com/5becc4ee-9fb4-469e-a864-28961aa74adb/-/preview/764x1368/-/quality/smart/",
  "https://ucarecdn.com/ac909d52-7cdb-408a-b645-8b5476f5fcc2/-/preview/764x1368/-/quality/smart/",
  "https://ucarecdn.com/46efe015-6bea-45bf-9f00-80b68f81c3ed/-/preview/764x1368/-/quality/smart/",
  "https://ucarecdn.com/9e1838f4-41f1-4cea-becc-89815d57d02d/-/preview/764x1368/-/quality/smart/",
  "https://ucarecdn.com/fcbf0ba4-1d21-4f06-a78b-37f1e8e72036/-/preview/764x1368/-/quality/smart/",
  "https://ucarecdn.com/71b7962a-fd5a-43a1-ac50-2e75a6a26878/-/preview/764x1368/-/quality/smart/",
  "https://ucarecdn.com/a832115d-8872-418e-b4a8-6dd69e8230e2/-/preview/764x1368/-/quality/smart/",
  "https://ucarecdn.com/d52dca2b-2da8-4633-848e-a383b4d1576d/-/preview/764x1368/-/quality/smart/",
  "https://ucarecdn.com/3c5eff18-1455-40c4-b310-36ff3ea5e280/-/preview/764x1368/-/quality/smart/",
  "https://ucarecdn.com/dc0dfa17-014e-4651-aafe-6bab48bbeaa7/-/preview/764x1368/-/quality/smart/",
  "https://ucarecdn.com/f9f68d72-61b2-42da-806c-873a3abdd7c4/-/preview/764x1368/-/quality/smart/",
  "https://ucarecdn.com/d8bd017f-87a3-4a0a-bb7e-d0ff45a7d494/-/preview/764x1368/-/quality/smart/",
  "https://ucarecdn.com/39beb9f1-4ff8-4112-a9fd-ae84db34efbd/-/preview/764x1368/-/quality/smart/",
  "https://ucarecdn.com/53ec4b0c-1482-4b18-b6d5-a64b9f56d484/-/preview/764x1368/-/quality/smart/",
  "https://ucarecdn.com/5006a21a-d8f6-4675-97a2-8aa2a70d738b/-/preview/764x1368/-/quality/smart/",
  "https://ucarecdn.com/1e741f59-c626-4965-9ec7-cf760b0fe20f/-/preview/764x1368/-/quality/smart/",
  "https://ucarecdn.com/6dec4041-2435-4b51-a770-d635bf003ea5/-/preview/764x1368/-/quality/smart/",
  "https://ucarecdn.com/e6afed0e-e981-417b-9421-378e2859fe15/-/preview/764x1368/-/quality/smart/",
  "https://ucarecdn.com/d1415ef0-c2d4-4389-9cd6-3e07aac736bb/-/preview/764x1368/-/quality/smart/",
  "https://ucarecdn.com/d203426a-9e60-4e37-8f78-5a9f0c16093b/-/preview/764x1368/-/quality/smart/",
  "https://ucarecdn.com/a7959731-31e2-479c-a91e-7c7825e3f51c/-/preview/764x1368/-/quality/smart/",
  "https://ucarecdn.com/c775ba59-a984-4749-8689-40420b91a60b/-/preview/764x1368/-/quality/smart/",
  "https://ucarecdn.com/ad9864d3-03ae-4307-9444-ce88bedeaa01/-/preview/764x1368/-/quality/smart/",
];

const gallery = () => {
  let gallery = "";
  images.forEach((i) => {
    gallery += `<div class="container"><div class="img-container"><img class="img" src="${i}" /></div></div>`;
  });
  return gallery;
};

const initParalaxImages = (parentElement) => {
  const images = parentElement.querySelectorAll(".img");
  [...images].forEach((el) => {
    new SmoothParalax(el);
  });
};

const pageContent = `
  <header class="header">
    <p class="h-t">2023</p>
    <a class="h-t" href="/">Back</a>
  </header>
  <div class="wrap">
    ${gallery()}
  </div>
`;

const initStill = (parentElement) => {
  const html = pageContent;

  parentElement.innerHTML = html;

  initParalaxImages(parentElement);
};

export default initStill;
