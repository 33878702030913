const pageContent = `
    <header class="header">
        <p class="h-t">2023</p>
        <a class="h-t" href="/">Back</a>
    </header>   
    <div class="about">
        <h1 class="t">Professional Drone Pilot</h1>
        <p class="lk-sub c">As a passionate drone pilot, I love nothing more than taking to the skies and capturing stunning aerial footage that showcases the world from a whole new perspective.</p>
        <p class="lk-sub c">Experience the versatility of my drone piloting skills, combining the stability of traditional methods with the dynamic movement of FPV, to deliver cinematic footage that is truly captivating.</p>
        <section>
            <h2 class="t">Gear</h2>
            <p class="lk-sub c m m-t">Mavic 3 Cine</p>
            <p class="lk-sub c m">Cinewhoop FPV</p>
            <p class="lk-sub c m">Drone Racer FPV</p>
            <p class="lk-sub c m">Sony FX30</p>
            <p class="lk-sub c m">GoPro Hero 10</p>
            <p class="lk-sub c m">GoPro Hero 8 Naked</p>
        </section>
        <section>
            <h2 class="t">Work with</h2>
            <p class="lk-sub c m m-t">Biotherm</p>
            <p class="lk-sub c m">Ubisoft</p>
            <p class="lk-sub c m">IBM France</p>
            <p class="lk-sub c m">Ogilvy</p>
            <p class="lk-sub c m">Twenty Two</p>
        </section>
        <section>
            <h2 class="t">Credits</h2>
            <p class="lk-sub c m m-t">Frames Dealer</p>
            <p class="lk-sub c m sm">Shot producer and premium footage</p>
            <p class="lk-sub c m">Rikoo</p>
            <p class="lk-sub c m sm">Motion and Development</p>
        </section>
        <section>
            <h2 class="t">Contact</h2>
            <p class="lk-sub c m m-t">Email</p>
            <p class="lk-sub c m">Instagram</p>
            <p class="lk-sub c m">Linkedin</p>
        </section>
    </div>
    <footer class="footer">
        <p class="leg">Design and developed by Rikoo</p>
        <p class="leg">All right reserved felix maillet &copy; 2023</p>
    </footer>
`;

export const initAbout = (parentElement) => {
  const html = pageContent;

  parentElement.innerHTML = html;
};
