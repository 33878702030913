import gsap, { Power4 } from "gsap";
import { initHome } from "./views/home";
import { initAbout } from "./views/about";
import initStill from "./views/still";
import initGlacier from "./views/glacier";
import initSand from "./views/sand";

export default class Mobile {
  constructor() {
    this.pages = {
      "/": initHome,
      "/about": initAbout,
      "/still": initStill,
      "/work/glacier": initGlacier,
      "/work/sand": initSand,
    };

    this.isLoaded = false;

    this.init();
    this.loader();
  }

  isUrlValid(url) {
    if (url in this.pages) {
      return url;
    }
    return "/";
  }

  navigateTo(url) {
    const u = this.isUrlValid(url);

    window.history.pushState({}, "", u);

    if (!this.isLoaded) {
      this.pages[u](document.getElementById("main"));
      this.handleClickEvent();
    } else {
      this.transitionIn(document.getElementById("tr"), () => {
        this.pages[u](document.getElementById("main"));
        window.scrollTo(0, 0);
        this.handleClickEvent();
        this.transitionOut(document.getElementById("tr"));
      });
    }
  }

  handleClickEvent() {
    [...document.querySelectorAll('a[href^="/"]')].forEach((link) => {
      link.addEventListener("click", (event) => {
        event.preventDefault();
        const url = link.getAttribute("href");
        this.navigateTo(url);
      });
    });
  }

  transitionIn(el, handleComplete) {
    gsap.to(el, {
      opacity: 1,
      duration: 0.4,
      ease: Power4.easeOut,
      onComplete: () => handleComplete(),
    });
  }

  transitionOut(el) {
    gsap.to(el, {
      opacity: 0,
      duration: 0.4,
      ease: Power4.easeOut,
    });
  }

  loader() {
    const load = document.getElementById("load");
    gsap.to(load, {
      opacity: 0,
      duration: 0.8,
      ease: Power4.easeOut,
      onComplete: () => {
        load.style.pointerEvents = "none";
        this.isLoaded = true;
      },
    });
  }

  init() {
    const url = window.location.pathname;

    if (!url.length) url = "/";

    window.addEventListener("popstate", () => {
      this.navigateTo(url);
    });

    window.route = this.navigateTo(url);
  }
}

// export default class Mobile {
//   constructor() {
//     this.routes = {
//       "/": {
//         template: initHome,
//         title: "Home",
//         description: "This is the home page",
//       },
//       "/about": {
//         template: initAbout,
//         title: "About Us",
//         description: "This is the about page",
//       },
//     };

//     this.loader = document.getElementById("load");
//     this.transition = document.getElementById("tr");

//     this.transitionAllowed = false;

//     this.init();
//   }

//   route(event) {
//     event = event;
//     event.preventDefault();
//     window.history.pushState({}, "", event.currentTarget.href);
//     this.locationHandler();
//   }

//   locationHandler() {
//     const location = window.location.pathname;

//     if (location.length == 0) {
//       location = "/";
//     }

//     const route = this.routes[location] || this.routes["404"];

//     route.template(document.getElementById("main"));

//     if (this.transitionAllowed) {
//       this.transitionIn(() => {
//         // document.getElementById("main").innerHTML = html;
//         this.linksEventListener();
//         this.transitionOut();
//       });
//     } else {
//       // document.getElementById("main").innerHTML = html;
//       this.linksEventListener();
//     }

//     document.title = route.title;

//     document
//       .querySelector('meta[name="description"]')
//       .setAttribute("content", route.description);
//   }

//   linksEventListener() {
//     const links = document.getElementsByTagName("a");
//     [...links].forEach((link) => {
//       link.addEventListener("click", (event) => {
//         event.preventDefault();
//         this.route(event);
//       });
//     });
//   }

//   loaderOutAnimation() {
//     gsap.to(this.loader, {
//       opacity: 0,
//       duration: 0.8,
//       ease: Power4.easeOut,
//       onComplete: () => {
//         this.loader.style.pointerEvents = "none";
//         this.transitionAllowed = true;
//       },
//     });
//   }

//   transitionIn(handleComplete) {
//     gsap.to(this.transition, {
//       opacity: 1,
//       duration: 0.4,
//       ease: Power4.easeOut,
//       onComplete: () => handleComplete(),
//     });
//   }

//   transitionOut() {
//     gsap.to(this.transition, {
//       opacity: 0,
//       duration: 0.4,
//       ease: Power4.easeOut,
//     });
//   }

//   init() {
//     window.addEventListener("popstate", (event) => {
//       this.locationHandler();
//     });
//     window.route = this.route;
//     this.locationHandler();
//     this.loaderOutAnimation();
//   }
// }
