export default class SmoothParalax {
  constructor(element, options = {}) {
    this.element = element;
    this.options = Object.assign(
      {
        speed: 10,
        scale: 1.1,
      },
      options
    );

    this.scrollY = window.scrollY;
    this.top = this.scrollY + this.element.getBoundingClientRect().top;

    this._loop();
  }

  _loop() {
    const scrollY = window.scrollY;
    if (this.scrollY !== scrollY) {
      this.scrollY = scrollY;
      this._scroll();
    }

    window.requestAnimationFrame(this._loop.bind(this));
  }

  _scroll() {
    if (!this._isInViewport()) {
      return;
    }

    this.element.style.transform =
      "scale(1.1) translateY(" +
      ((this.scrollY - this.top) * this.options.speed) / 100 +
      "px)";
  }

  _isInViewport() {
    const elementTop = this.element.offsetTop;
    const elementBottom = this.element.offsetTop + this.element.offsetHeight;
    const viewportBottom = this.scrollY + window.innerHeight;

    return elementBottom > this.scrollY && elementTop < viewportBottom;
  }
}
