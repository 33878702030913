const pageContent = `
<header class="header">
    <p class="h-t">2023</p>
    <a class="h-t" href="/">back</a>
</header>
`;

const initGlacier = (parentElement) => {
  const html = pageContent;

  parentElement.innerHTML = html;
};

export default initGlacier;
