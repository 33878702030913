import SmoothParalax from "../utils/paralax";

const pageContent = `
    <header class="header">
        <p class="h-t">2023</p>
        <a class="h-t" href="/about">About</a>
    </header>
    <div class="wrap">
        <a class="container" href="/work/glacier">
            <div class="img-container">
                <img class="img" src="https://ucarecdn.com/a832115d-8872-418e-b4a8-6dd69e8230e2/-/preview/764x1368/-/quality/smart/" alt="noun picture"/>
            </div>
            <div class="lk">
                <p class="t">The Frozen Frontier</p>
                <p class="lk-sub">discover</p>
            </div>
        </a>
        <a class="container" href="/work/sand">
            <div class="img-container">
                <img class="img" src="https://ucarecdn.com/3c5eff18-1455-40c4-b310-36ff3ea5e280/-/preview/764x1368/-/quality/smart/" alt="dune picture"/>
            </div>
            <div class="lk">
                <p class="t">Walking on Sands</p>
                <p class="lk-sub">discover</p>
            </div>
        </a>
        <a class="container" href="/work/sun">
            <div class="img-container">
                <img class="img" src="https://ucarecdn.com/1e741f59-c626-4965-9ec7-cf760b0fe20f/-/preview/764x1368/-/quality/smart/" alt="sun picture"/>
            </div>
            <div class="lk">
                <p class="t">lorem ipsum</p>
                <p class="lk-sub">discover</p>
            </div>
        </a>
        <a class="container" href="/work/showreel">
            <div class="img-container">
                <img class="img" src="https://ucarecdn.com/5006a21a-d8f6-4675-97a2-8aa2a70d738b/-/preview/764x1368/-/quality/smart/" alt="showreel picture"/>
            </div>
            <div class="lk">
                <p class="t">lorem ipsum</p>
                <p class="lk-sub">discover</p>
            </div>
        </a>
    </div>
    <footer class="footer">
        <p class="t">Felix Maillet<br>Portfolio</p>
        <div class="f-row">
            <a class="link" href="/still">still</a>
            <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 243.58 252.99">
                <defs>
                    <style>
                    .cls-1 {
                        fill: #141414;
                    }
                    </style>
                </defs>
                <g id="Layer_2-2" data-name="Layer 2">
                    <path class="cls-1" d="M61.86,0H241.62c1.08,0,1.96,.88,1.96,1.96V180.77c0,2.13-.83,4.17-2.32,5.7l-64.7,66.51,1.1-139.49c0-1.24-1.49-1.87-2.37-1L46.03,240.11c-1.1,1.08-2.86,1.08-3.96-.01L1.19,199.21c-.66-.66-.65-1.72,0-2.37L131.05,67.49c.88-.87,.26-2.37-.97-2.38L0,64.38,56,2.6c1.5-1.66,3.63-2.6,5.86-2.6Z"/>
                </g>
            </svg>
        </div>
        <div class="f-row">
            <a class="link" href="/about">about</a>
            <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 243.58 252.99">
                <defs>
                    <style>
                    .cls-1 {
                        fill: #141414;
                    }
                    </style>
                </defs>
                <g id="Layer_2-2" data-name="Layer 2">
                    <path class="cls-1" d="M61.86,0H241.62c1.08,0,1.96,.88,1.96,1.96V180.77c0,2.13-.83,4.17-2.32,5.7l-64.7,66.51,1.1-139.49c0-1.24-1.49-1.87-2.37-1L46.03,240.11c-1.1,1.08-2.86,1.08-3.96-.01L1.19,199.21c-.66-.66-.65-1.72,0-2.37L131.05,67.49c.88-.87,.26-2.37-.97-2.38L0,64.38,56,2.6c1.5-1.66,3.63-2.6,5.86-2.6Z"/>
                </g>
            </svg>
        </div>
        <div class="f-row">
            <a class="link" href="/about">get in touch</a>
            <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 243.58 252.99">
                <defs>
                    <style>
                    .cls-1 {
                        fill: #141414;
                    }
                    </style>
                </defs>
                <g id="Layer_2-2" data-name="Layer 2">
                    <path class="cls-1" d="M61.86,0H241.62c1.08,0,1.96,.88,1.96,1.96V180.77c0,2.13-.83,4.17-2.32,5.7l-64.7,66.51,1.1-139.49c0-1.24-1.49-1.87-2.37-1L46.03,240.11c-1.1,1.08-2.86,1.08-3.96-.01L1.19,199.21c-.66-.66-.65-1.72,0-2.37L131.05,67.49c.88-.87,.26-2.37-.97-2.38L0,64.38,56,2.6c1.5-1.66,3.63-2.6,5.86-2.6Z"/>
                </g>
            </svg>
        </div>
        <div id="top">
            <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 243.58 252.99">
                <defs>
                    <style>
                    .cls-1 {
                        fill: #141414;
                    }
                    </style>
                </defs>
                <g id="Layer_2-2" data-name="Layer 2">
                    <path class="cls-1" d="M61.86,0H241.62c1.08,0,1.96,.88,1.96,1.96V180.77c0,2.13-.83,4.17-2.32,5.7l-64.7,66.51,1.1-139.49c0-1.24-1.49-1.87-2.37-1L46.03,240.11c-1.1,1.08-2.86,1.08-3.96-.01L1.19,199.21c-.66-.66-.65-1.72,0-2.37L131.05,67.49c.88-.87,.26-2.37-.97-2.38L0,64.38,56,2.6c1.5-1.66,3.63-2.6,5.86-2.6Z"/>
                </g>
            </svg>    
        </div>
    </footer>
`;

const initParalaxImages = (parentElement) => {
  const images = parentElement.querySelectorAll(".img");
  [...images].forEach((el) => {
    new SmoothParalax(el);
  });
};

export const initHome = (parentElement) => {
  const html = pageContent;

  parentElement.innerHTML = html;

  initParalaxImages(parentElement);

  document.getElementById("top").addEventListener("click", () =>
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  );
};
