import UAParser from "ua-parser-js";
import Mobile from "./mobile/router";
import Desktop from "./desktop/router";

const ua = new UAParser();
const device = ua.getDevice();

window.addEventListener("DOMContentLoaded", () =>
  device.type === "mobile" || device.type === "tablet"
    ? new Mobile()
    : new Desktop()
);
